// $primary-color: #0F70D4;
$primary-color: #0F70D4;
// $primary-color: #0464ea;
$green-color: #39C641;


* {
  margin: 0;padding: 0;
  box-sizing: border-box;
  line-height: 1;
  // font-family: 'Montserrat', sans-serif;
  font-family: 'Archivo', sans-serif;
}

body {
  font-size: 62.5%;
  background-color: #fff;
}

a {
  text-decoration: none;
}

.h1 {
  font-size: clamp(2.6rem, 5vw, 3.2rem);
  font-weight: 700;
  color: #f5f5f5;
  line-height: 3rem;
}

.h2 {
  font-size: 1.5rem;
  font-weight: 400;
  color: #f5f5f5;
  line-height: 2rem;
}

.h3 {
  font-size: clamp(20px, 2vw, 24px);
  font-weight: 500;
  
  color: #f5f5f5;
  line-height: clamp(1.7rem, 3vw, 2rem);
}

.h4 {
  font-size: clamp(14px, 2vw, 18px);
  font-weight: 500;
  
  color: #ccc;
  line-height: clamp(1.1rem, 3vw, 1.5rem);
}



// Swal modal
.swal2-html-container {
  overflow: unset !important;
}

.swal2-confirm {
  background-color: #fff!important;
  outline: none;
  border: 3px solid #0464ea!important;
  border-radius: 25px!important;
  color: #000!important;
  font-size: 16px!important;
  font-weight: 500;
  letter-spacing: .3px;
  margin-top: 0;
  padding: 12px 24px;
  cursor: pointer;
  text-decoration: none;
  transition: all .2s;

  &:hover {
    transition: all .2s;
    transform: scale(1.05);
  }

  &:nth-child(2) {
    border-color: #39C641 !important;
  }
}




//Scrollbar
/* Estilos para la barra de desplazamiento en navegadores WebKit (Chrome, Safari) */
::-webkit-scrollbar {
  width: 8px; /* Ancho de la barra de desplazamiento */
}

::-webkit-scrollbar-track {
  background: #222; /* Color de fondo de la pista de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb {
  background: #444; /* Color del mango de la barra de desplazamiento */
  border-radius: 4px; /* Radio de borde del mango de la barra de desplazamiento */
}

::-webkit-scrollbar-thumb:hover {
  background: #666; /* Color del mango de la barra de desplazamiento al pasar el mouse */
}
