.TextArea {
    resize: none;
    width: 100%;
    outline: none;
    border: none;
    border-bottom: 2px solid lightgray;
    background-color: transparent;
    border-radius: 10px 10px 0 0;
    font-size: clamp(20px, 2vw, 22px);
    color: #e5e5e5;
}